
html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
    height: 100%;
}

.tabGroupTable .MuiIconButton-root {
  padding: 0px;
}

#monitoring-table .MuiTable-root {
  width: 100%;
  margin: 0;
}

.MuiSelect-iconOutlined {
  z-index: 50000;
}

.tabGroupTable .MuiTable-root {
  background: #FFF;
  margin: 0px 32px 0px;
  width: calc(100% - 64px);
  border-radius: 4px 4px 0px 0px;
  /*height: 596px;*/
  /*margin-top: 0px;*/
}

.MuiTableBody-root .MuiTableCell-root	{
  padding: 12px;
} 

.MuiTableCell-head	{
  padding: 12px !important;
}

/*.tabGroupTable .MuiTableRow-root {
  height: 52px;
}

.tabGroupTable .MuiTableCell-root {
  height: 53px;
}*/

/*.tabGroupTable .MuiTablePagination-toolbar {
  height: 54px;
}*/

.MuiInputBase-input.Mui-disabled {
  color: #9798B0;
}

.tabGroupTable .MuiTableCell-root { 
  border-top-left-radius: 10px; 
}

/*.Component-horizontalScrollContainer-125{
  padding-left: 32px;
  padding-right: 32px;
}*/

/*.tabGroupTable .MuiTableCell-head { This does nothing
  background: #Faa;
  padding-bottom: 1000px;
  margin-bottom: 10px;
  margin-top: 0px;
}*/

/*.tabGroupTable .MuiOutlinedInput-root {
    border: '1px solid white';
}*/

.App {
  height: 100vh;
}

/*html {*/
/*  height: 100%,*/
/*}*/

/*.App-link {*/
/*  color: #09d3ac;*/
/*}*/



.incomplete-filter {
  color: #949eb1 !important;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  background-color: transparent !important;
  border: 0 !important;
  padding: 6px 8px !important;
  box-shadow: none !important;

}

.incomplete-filter.active {
  color: #18bff6 !important;
}