@import url('https://fonts.googleapis.com/icon?family=Material+Icons');
@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap');

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.rstcustom__row {
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.rstcustom__rowLabel {
  padding-left: 4px;
  width: 100%;
}

div.rstcustom__rowContents {
  width: 75%;
}

.rstcustom__rowWrapper:hover {
  opacity: 1;
}

div.rstcustom__rowWrapper {
  cursor: pointer;
}

div.rstcustom__rowContents {
  cursor: pointer;
}

div.rstcustom__node {
  border: "1px solid lightgrey";
  padding: 0px 8px;
}

button.rstcustom__collapseButton {
  padding-left: 16px;
}

button.rstcustom__expandButton {
  padding-left: 16px;
}

div.rst__rowWrapper {
}
