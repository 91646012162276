@import url(https://fonts.googleapis.com/icon?family=Material+Icons);
@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500,700&display=swap);
body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

div.rstcustom__row {
  width: 100%;
  box-sizing: border-box;
  background-color: white;
  border-radius: 4px;
  cursor: pointer;
  overflow: hidden;
  text-overflow: ellipsis;
}

div.rstcustom__rowLabel {
  padding-left: 4px;
  width: 100%;
}

div.rstcustom__rowContents {
  width: 75%;
}

.rstcustom__rowWrapper:hover {
  opacity: 1;
}

div.rstcustom__rowWrapper {
  cursor: pointer;
}

div.rstcustom__rowContents {
  cursor: pointer;
}

div.rstcustom__node {
  border: "1px solid lightgrey";
  padding: 0px 8px;
}

button.rstcustom__collapseButton {
  padding-left: 16px;
}

button.rstcustom__expandButton {
  padding-left: 16px;
}

div.rst__rowWrapper {
}


html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

#root {
    height: 100%;
}

.tabGroupTable .MuiIconButton-root {
  padding: 0px;
}

#monitoring-table .MuiTable-root {
  width: 100%;
  margin: 0;
}

.MuiSelect-iconOutlined {
  z-index: 50000;
}

.tabGroupTable .MuiTable-root {
  background: #FFF;
  margin: 0px 32px 0px;
  width: calc(100% - 64px);
  border-radius: 4px 4px 0px 0px;
  /*height: 596px;*/
  /*margin-top: 0px;*/
}

.MuiTableBody-root .MuiTableCell-root	{
  padding: 12px;
} 

.MuiTableCell-head	{
  padding: 12px !important;
}

/*.tabGroupTable .MuiTableRow-root {
  height: 52px;
}

.tabGroupTable .MuiTableCell-root {
  height: 53px;
}*/

/*.tabGroupTable .MuiTablePagination-toolbar {
  height: 54px;
}*/

.MuiInputBase-input.Mui-disabled {
  color: #9798B0;
}

.tabGroupTable .MuiTableCell-root { 
  border-top-left-radius: 10px; 
}

/*.Component-horizontalScrollContainer-125{
  padding-left: 32px;
  padding-right: 32px;
}*/

/*.tabGroupTable .MuiTableCell-head { This does nothing
  background: #Faa;
  padding-bottom: 1000px;
  margin-bottom: 10px;
  margin-top: 0px;
}*/

/*.tabGroupTable .MuiOutlinedInput-root {
    border: '1px solid white';
}*/

.App {
  height: 100vh;
}

/*html {*/
/*  height: 100%,*/
/*}*/

/*.App-link {*/
/*  color: #09d3ac;*/
/*}*/



.incomplete-filter {
  color: #949eb1 !important;
  font-size: 14px;
  line-height: 14px;
  font-weight: 500;
  background-color: transparent !important;
  border: 0 !important;
  padding: 6px 8px !important;
  box-shadow: none !important;

}

.incomplete-filter.active {
  color: #18bff6 !important;
}
.quill .ql-toolbar {
    border: none;
    z-index: 2;
    background-color: white;
    position: -webkit-sticky;
    position: sticky; 
    top: -10px;
}

.ql-editor {
    border: none;
}

.ql-container.ql-snow {
    border: none;
}

#verification-record-editor .quill .ql-toolbar {
    top: -10px;
}
