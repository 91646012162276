.quill .ql-toolbar {
    border: none;
    z-index: 2;
    background-color: white;
    position: sticky; 
    top: -10px;
}

.ql-editor {
    border: none;
}

.ql-container.ql-snow {
    border: none;
}

#verification-record-editor .quill .ql-toolbar {
    top: -10px;
}